import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import NavButton from '@/components/UI/NavButton';

import Industries from '../Industries';
import IntroBranches from './IntroBranches/';
import IntroDescription from './IntroDescription/';
import IntroPieChartBlock from './IntroPieChartBlock/';
import styles from './styles.module.scss';

const Intro = ({
    title,
    industries,
    description,
    pieChart,
    branches,
    bigTitle,
    onScrollDownButtonClick,
    introRight
}) => (
    <div className={styles.root}>
        <div className={styles.introBox}>
            <div className={styles.introLeft}>
                <div className={styles.top}>
                    <h1 className={cx('H1', styles.title)}>{title}</h1>
                    {industries && <Industries industries={industries} />}
                    {branches && <IntroBranches title={branches.title} list={branches.list} />}
                    {description && <IntroDescription text={description} />}
                </div>
            </div>
            {introRight && <div className={styles.introRight}>{introRight}</div>}
        </div>

        <div className={styles.bottom}>
            <div className={styles.bottomLeft}>
                <NavButton onClick={onScrollDownButtonClick} type={'arrow-down'} size={'medium'} />
            </div>
            <div className={styles.bottomRight}>
                {pieChart && pieChart.value && (
                    <IntroPieChartBlock
                        value={pieChart.value}
                        prefix={pieChart.prefix}
                        description={pieChart.description}
                    />
                )}
                {bigTitle && !pieChart && (
                    <div className={styles.bigTitle} dangerouslySetInnerHTML={{ __html: bigTitle }} />
                )}
            </div>
        </div>
    </div>
);

Intro.propTypes = {
    title: PropTypes.string.isRequired,
    bigTitle: PropTypes.string,
    description: PropTypes.string,
    onScrollDownButtonClick: PropTypes.func.isRequired,
    pieChart: PropTypes.shape({
        value: PropTypes.number,
        description: PropTypes.string
    }),
    branches: PropTypes.shape({
        title: PropTypes.string,
        list: PropTypes.arrayOf(PropTypes.string)
    })
};

Intro.defaultProps = {
    title: 'Заголовок страницы'
};

export default Intro;
