import { compose, withProps, withState, lifecycle, withHandlers } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, InfoWindow } from 'react-google-maps';
import HeatmapLayer from 'react-google-maps/lib/components/visualization/HeatmapLayer';
import PropTypes from 'prop-types';
import React from 'react';

import NavButton from '@/components/UI/NavButton';

import { HEATMAP_GRADIENTS, HEATMAP_MAX_ZOOM, HEATMAP_STYLES } from '../../consts';
import styles from './styles.module.scss';

const Heatmap = (props) => (
    <>
        <GoogleMap
            ref={props.onMapMounted}
            onZoomChanged={props.onZoomChanged}
            onClick={props.handleMapClick}
            defaultZoom={props.defaultZoom}
            zoom={props.zoom}
            defaultCenter={props.center}
            defaultOptions={{
                disableDefaultUI: true,
                styles: HEATMAP_STYLES,
                maxZoom: HEATMAP_MAX_ZOOM
            }}>
            {props.hint && (
                <InfoWindow position={props.hint.clickCoords}>
                    <div>{props.hint.value}</div>
                </InfoWindow>
            )}
            {props.heatmapData && (
                <HeatmapLayer
                    data={props.heatmapData}
                    options={{
                        radius: props.radius,
                        opacity: 0.7,
                        maxIntensity: props.maxIntensity,
                        gradient: HEATMAP_GRADIENTS
                    }}
                />
            )}
            {/*{props.data2 && (*/}
            {/*<MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>*/}
            {/*{props.data2.map((latLng, i) => (*/}
            {/*<Marker key={i} position={latLng} />*/}
            {/*))}*/}
            {/*</MarkerClusterer>*/}
            {/*)}*/}
        </GoogleMap>
        <div className={styles.controls}>
            <NavButton onClick={props.zoomOut} type={'minus'} size={'small'} bgColor={'white'} marginRight />
            <NavButton onClick={props.zoomIn} type={'plus'} size={'small'} bgColor={'white'} />
        </div>
    </>
);

Heatmap.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            // lat: PropTypes.number,
            // lng: PropTypes.number,
        })
    ),
    zoom: PropTypes.number,
    center: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number
    }).isRequired
};

Heatmap.defaultProps = {
    zoom: 10,
    radius: 30,
    maxIntensity: 30
};

const heatmapHandlers = {
    handleMapClick: ({ showHint, valueKey, setHint, data, unitDivider, units }) => (e) => {
        if (showHint) {
            const clickCoords = e.latLng;

            const closestDataPoint = data
                .filter((point) => point[valueKey] / unitDivider !== 0)
                .reduce((closest, current) => {
                    const currentCoords = new window.google.maps.LatLng(current.lat, current.lng);
                    const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
                        clickCoords,
                        currentCoords
                    );
                    const currentWidthDistance = { ...current, distance };
                    return closest
                        ? closest.distance > distance
                            ? currentWidthDistance
                            : closest
                        : currentWidthDistance;
                }, null);
            setHint(
                closestDataPoint.distance < 1000
                    ? {
                          value: `${(closestDataPoint[valueKey] / unitDivider).toFixed(2)} ${units}`,
                          clickCoords
                      }
                    : null
            );
        }
    }
};

const heatmapHoc = compose(
    withProps({
        googleMapURL:
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyBCWWvu9kgXa7a0vun2iDO7kK1nY-mPXE4&v=3.exp&libraries=visualization,geometry',
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap,
    withState('heatmapData', 'setHeatmapData', []),
    withState('hint', 'setHint', null),
    withHandlers(heatmapHandlers),
    lifecycle({
        componentDidMount() {
            const { data, setHeatmapData, getWeight, valueKey } = this.props;
            setHeatmapData(
                data.map((v) => {
                    return {
                        location: new window.google.maps.LatLng(Number(v.lat), Number(v.lng)),
                        weight: getWeight(v[valueKey])
                    };
                })
            );
        }
    })
);

export default heatmapHoc(Heatmap);
