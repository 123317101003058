/**
 * Rounds `value` with `len` digits
 * @param value
 * @param len
 * @returns {number}
 */
const roundInt = (value, len = 0) => {
    let mul = Math.pow(10, len);

    return Math.round(value * mul) / mul;
};

export default roundInt;
