import React, { Component } from 'react';
import { Link } from 'gatsby';
import styles from './styles.module.scss';
import cx from 'classnames';

class ServicesPageServiceLink extends Component {
    render() {
        const { path, url, children } = this.props;
        if (path) {
            return <Link to={path}>{children}</Link>;
        }

        if (url) {
            return (
                <a href={url} target={'_blank'}>
                    {children}
                </a>
            );
        }

        return <div>{children}</div>;
    }
}

class ServicesPageService extends Component {
    render() {
        const { title, img, path, url, classKey } = this.props;
        return (
            <div className={cx(styles.item, styles[classKey])}>
                <ServicesPageServiceLink path={path} url={url}>
                    <div
                        className={styles.itemContent}
                        style={{
                            backgroundImage: `url(${img})`
                        }}>
                        <div className={styles.itemTitle}>{title}</div>
                    </div>
                </ServicesPageServiceLink>
            </div>
        );
    }
}

class ServicesPage extends Component {
    render() {
        const { links } = this.props;

        return (
            <div className={styles.root}>
                <div className={styles.grid}>
                    {links.map((link, i) => (
                        <ServicesPageService key={i} {...link} />
                    ))}
                </div>
            </div>
        );
    }
}

export default ServicesPage;
