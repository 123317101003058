import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import NavButton from '@/components/UI/NavButton';
import ServiceCard from '@/components/ServiceCard';

import { INDEX_ROUTE } from '../../consts';
import styles from './styles.module.scss';

const Footer = ({ links, onTopButtonClick }) => (
    <footer className={cx(styles.root, links && links.list && links.list.length > 0 && styles.hasLink)}>
        <div className={styles.inner}>
            <div>
                {links && (
                    <div className={styles.links}>
                        <div className={styles.title}>
                            <div className="H2">{links.title}</div>
                        </div>
                        <div className={styles.linksList}>
                            {links.list.map((link, i) => (
                                <span key={i}>
                                    <ServiceCard
                                        icon={link.icon}
                                        title={link.title}
                                        text={link.text}
                                        marginRight={i % 2 !== 0}
                                    />
                                </span>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.buttons}>
                <div className={styles.button}>
                    <Link to={INDEX_ROUTE}>
                        <NavButton type={'arrow-left'} size={'medium'} marginRight />
                    </Link>
                    <div className={styles.buttonText}>
                        На главный <br />
                        экран
                    </div>
                </div>
                <div className={styles.button}>
                    <NavButton onClick={onTopButtonClick} type={'arrow-up'} size={'medium'} />
                    <div className={styles.buttonText}>
                        К началу <br />
                        страницы
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

Footer.propTypes = {
    links: PropTypes.object,
    onTopButtonClick: PropTypes.func.isRequired
};

export default Footer;
