import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import SEO from '@/components/seo';
import NoSSR from '@/components/no-ssr';
import { Section } from '@/components/UI/Section';
import Layout from '@/components/layout';
import ChartHeader from '@/components/ChartHeader';
import BillboardsMap from '@/components/BillboardsMap';

const PAGE = {
  pageTitle: 'Оптимизация размещения наружной рекламы',
  sectionTitle: 'оптимизация размещения рекламы',
  pageDescription:
    'Сервис на&nbsp;основе искусственного интеллекта и&nbsp;технологий машинного обучения, позволяющий повысить эффективность от&nbsp;размещения OOH и&nbsp;DOOH рекламы. Сервис позволит автоматизировать медиапланирование и&nbsp;таргетирование, увеличить охват и&nbsp;снизить расходы.',
  introPieChart: {
    value: 20,
    prefix: 'до',
    description:
      'Столько денег в&nbsp;среднем экономят клиенты на&nbsp;размещении рекламы, используя сервис',
  },
};

const CHART_HEADER = {
  title: 'Билборды',
  labels: [
    { text: 'Исходное состояние', color: '#731982' },
    { text: 'После оптимизации', color: '#00B956' },
    { text: 'Не изменили положения', color: '#FEB123' },
  ],
};

const BillboardsPage = ({ data }) => (
  <Layout page={PAGE}>
    <SEO title={PAGE.pageTitle} />
    <Section />
    <Section>
      <ChartHeader {...CHART_HEADER} />
      <NoSSR>
        <BillboardsMap
          data={data.billboards.edges.map(v => v.node)}
          center={{ lat: 55.78367, lng: 38.452822 }}
          zoom={13}
        />
      </NoSSR>
    </Section>
  </Layout>
);

const query = graphql`
  query {
    billboards: allBillboardsJson {
      edges {
        node {
          lat
          lng
          type
        }
      }
    }
  }
`;

export default () => <StaticQuery query={query} render={data => <BillboardsPage data={data} />} />;
