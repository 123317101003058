import React, { Component } from 'react';
import Layout from '@/components/layout';
import SEO from '@/components/seo';
import COVID from '@/components/COVID';
import NoSSR from '@/components/no-ssr';
import axios from 'axios';
import { COVID_ATTRIBUTES, COVID_VARIABLES, COVID_VARIABLE_INFO } from '@/consts';

const PAGE = {
    pageTitle: 'COVID-2019',
    sectionTitle: 'Самоскоринг по COVID-2019',
    pageDescription:
        'Сервис мониторинга для определения вероятности инфицирования коронавирусной инфекцией COVID-19 сотрудников предприятия',
    industries: [
        'Непрерывное действующие организации',
        'Компании с широкой офф-лайн сетью',
        'Компании с большим штатом сотрудников'
    ]
};

class COVIDPage extends Component {
    state = {
        isLoading: false,
        data: null
    };

    onSubmit = (value) => {
        this.setState({
            isLoading: true,
            hasError: false
        });
        const phoneNumber = value.replace(/\D/g, '');
        axios
            .post('/bigdata-back/api/risk', {
                // prod API endpoint
                //.post('https://bigdata.demo.megafon.ru/bigdata-back/api/risk', {
                // dev API endpoint
                data: {
                    msisdn: phoneNumber,
                    variables: COVID_VARIABLES,
                    attributes: COVID_ATTRIBUTES
                }
            })
            .then((result) => {
                this.setState({
                    isLoading: false
                });
                const hasVariables = result.data && result.data.data && result.data.data.variables;
                if (hasVariables) {
                    this.setState({
                        data: result.data.data.variables.reduce(
                            (agg, variable) => ({
                                ...agg,
                                [variable.name]: {
                                    ...variable,
                                    ...COVID_VARIABLE_INFO[variable.name]
                                }
                            }),
                            {}
                        )
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    isLoading: false,
                    hasError: true
                });
            });
    };

    render() {
        const { data, isLoading, hasError } = this.state;
        return (
            <Layout page={PAGE}>
                <SEO title={PAGE.pageTitle} />
                <NoSSR>
                    <COVID onSubmit={this.onSubmit} data={data} isLoading={isLoading} hasError={hasError} />
                </NoSSR>
            </Layout>
        );
    }
}

export default COVIDPage;
