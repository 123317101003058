import React, { useState } from 'react';
import cx from 'classnames';
import NoSSR from '@/components/no-ssr';
import IconPlus from '@/components/UI/IconPlus';
import { Button } from '@/components/UI/Button';
import styles from './styles.module.scss';

const ChartWrapper = ({
  title,
  desc,
  options,
  selectedOption,
  children,
  fullWidth,
  className,
  onOptionSelect,
}) => {
  const [isOptionsOpen, toggleOptionsState] = useState(false);

  return (
    <div className={cx(fullWidth ? styles.graphFullWidth : styles.graph, className)}>
      <div className={styles.graphInner}>
        <div className={styles.header}>
          <div className={'H3'}>
            {title} <span style={{ color: '#C4C4C4' }}>{desc}</span>
          </div>
          <div>
            {options && options.length > 0 && (
              <Button onClick={() => toggleOptionsState(!isOptionsOpen)}>
                <span className={styles.btnText}>
                  {selectedOption && selectedOption.text && selectedOption.text}
                </span>
                <IconPlus reverse={isOptionsOpen} />
              </Button>
            )}
          </div>
        </div>
        {options && options.length > 0 && (
          <div className={cx(styles.options, !isOptionsOpen && '_hidden')}>
            {options.map(e => (
              <Button
                key={e.value}
                className={cx(
                  styles.option,
                  selectedOption && selectedOption.value === e.value && '_active'
                )}
                onClick={() => {
                  toggleOptionsState(false);
                  onOptionSelect && typeof onOptionSelect === 'function' && onOptionSelect(e);
                }}
              >
                {e.text}
              </Button>
            ))}
          </div>
        )}
        {children && <NoSSR>{children}</NoSSR>}
      </div>
    </div>
  );
};

export default ChartWrapper;
