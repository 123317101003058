import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from '@/components/privateRoute';
import Dashboard from '@/components/Pages/Dashboard';
import DashboardBillboards from '@/components/Pages/Dashboard/billboards';
import DashboardLocations from '@/components/Pages/Dashboard/locations';
import DashboardNewClients from '@/components/Pages/Dashboard/new-clients';
import DashboardPortrait from '@/components/Pages/Dashboard/portrait';
import DashboardScoring from '@/components/Pages/Dashboard/scoring';
import COVIDPage from '@/components/Pages/Dashboard/covid';
import BdMegafon from '@/components/Pages/Dashboard/bdMegafon';
import AddPhone from '@/components/Pages/AddPhone';
import { AuthContextProvider } from '@/components/AuthContext';

const App = () => (
    <AuthContextProvider>
        <Router>
            <PrivateRoute path="/app" component={Dashboard} />
            <PrivateRoute path="/app/portrait" component={DashboardPortrait} />
            <PrivateRoute path="/app/scoring" component={DashboardScoring} />
            <PrivateRoute path="/app/locations" component={DashboardLocations} />
            <PrivateRoute path="/app/billboards" component={DashboardBillboards} />
            <PrivateRoute path="/app/new-clients" component={DashboardNewClients} />
            <PrivateRoute path="/app/covid" component={COVIDPage} />
            <PrivateRoute path="/app/bd-megafon" component={BdMegafon} />
            <PrivateRoute path="/app/add-phone" component={AddPhone} />
        </Router>
    </AuthContextProvider>
);

export default App;
