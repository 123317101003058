import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isPhone } from '@/components/utils/validator';
import InputMask from 'react-input-mask';
import { StyledButton } from '@/components/UI/Button';

import IconArrowRight from '@/assets/icons/arrow-right.svg';
import styles from './styles.module.scss';
import cx from 'classnames';

class ScoringForm extends Component {
    state = {
        value: ''
    };

    onChange = (e) => {
        this.setState({ value: e.target.value });
    };

    onSubmit = (e) => {
        const { value } = this.state;
        e.preventDefault();
        if (isPhone(value)) {
            this.props.onSubmit(value);
        }
    };

    render() {
        return (
            <form className={cx(styles.field, styles.inputField)} onSubmit={this.onSubmit}>
                <div className={cx(styles.fieldTitle, 'H3')}>Номер телефона</div>
                <InputMask
                    type={'text'}
                    className={styles.input}
                    placeholder={'Введите номер телефона'}
                    mask="+7 (999) 999-99-99"
                    value={this.state.value}
                    onChange={this.onChange}
                    ref={(ref) => (this.$mask = ref)}
                />
                <div className={styles.buttonWrap}>
                    <StyledButton type={'submit'} className={styles.button}>
                        <IconArrowRight />
                    </StyledButton>
                </div>
                <input
                    type="submit"
                    style={{
                        position: 'absolute',
                        left: -9999,
                        opacity: 0
                    }}
                />
            </form>
        );
    }
}

ScoringForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default ScoringForm;
