import React, { Component } from 'react';
import Layout from '@/components/layout';
import SEO from '@/components/seo';
import NoSSR from '@/components/no-ssr';
import { BigDataMegafon } from '@/components/BigDataMegafon';
import BdImage from '@/assets/images/bdMegafonTeaser.svg';

const PAGE = {
    pageTitle: 'Большие данные и МегаФон',
    pageDescription: 'Помогаем бизнесу и государству использовать потенциал данных в условиях цифровой трансформации',
    introRight: <BdImage />
};

class BdMegafon extends Component {
    render() {
        return (
            <Layout page={PAGE}>
                <SEO title={PAGE.pageTitle} />
                <NoSSR>
                    <BigDataMegafon />
                </NoSSR>
            </Layout>
        );
    }
}

export default BdMegafon;
