import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Profiling } from '@/components/BigDataMegafon/Profiling';
import streetMap from '@/assets/images/streetMap.png';
import BdMap from '@/assets/images/bdMap.svg';

export const BigDataMegafon = () => {
    const [tabState, setTabState] = useState('operatorData');

    return (
        <div {...{ className: styles.wrapper }}>
            <div {...{ className: styles.imgWrap }}>
                <BdMap />
            </div>

            <div {...{ className: styles.contentOuter }}>
                <div {...{ className: styles.header }}>Как работают продукты на основе больших данных</div>
                <div {...{ className: styles.tabWrap }}>
                    <div
                        {...{
                            className: tabState === 'operatorData' ? styles.tabCellActive : styles.tabCell,
                            onClick: () => setTabState('operatorData')
                        }}>
                        Данные оператора
                    </div>
                    <div
                        {...{
                            className: tabState === 'profiling' ? styles.tabCellActive : styles.tabCell,
                            onClick: () => setTabState('profiling')
                        }}>
                        Профилирование
                    </div>
                </div>

                <div {...{ className: styles.content }}>
                    {tabState === 'operatorData' && <img {...{ src: streetMap, alt: 'Маршрут' }} />}
                    {tabState === 'profiling' && <Profiling />}
                </div>
            </div>
        </div>
    );
};
