import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import IconMonitor from '@/assets/icons/service-monitor.svg';
import IconSegment from '@/assets/icons/service-segment.svg';

const ServiceCard = ({ title, text, icon, marginRight }) => (
  <div className={cx(styles.root, styles.marginRight)}>
    <div className={styles.icon}>
      <div className={styles.iconInner}>
        {icon === 'monitor' && <IconMonitor className={styles.svg} />}
        {icon === 'segment' && <IconSegment className={styles.svg} />}
      </div>
    </div>
    <div className={cx(styles.title, 'H3')} dangerouslySetInnerHTML={{ __html: title }} />
    <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
  </div>
);

ServiceCard.propTypes = {
  icon: PropTypes.oneOf(['monitor', 'segment']),
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default ServiceCard;
