import React, { useState } from 'react';
import pageStyles from './pageStyles.module.scss';

const CheckBoxComponent = ({ num, checkedState, onClick }) => {
    return (
        <div {...{ onClick, className: pageStyles.checkBoxComp }}>
            <div {...{ className: pageStyles.checkBoxWrap }}>
                <div {...{ className: pageStyles.checker }}>
                    {checkedState && <div {...{ className: pageStyles.checkerV }}></div>}
                </div>
            </div>
            <div {...{ className: pageStyles.numCell }}>{num}</div>
        </div>
    );
};

const PhoneRow = ({ addToBuffer, phoneNum, num }) => {
    const [checkedState, SetCheckedState] = useState(false);

    const onClick = () => {
        addToBuffer({ phoneNum });
        SetCheckedState(!checkedState);
    };

    return (
        <div {...{ className: checkedState ? pageStyles.phoneRowChecked : pageStyles.phoneRow }}>
            <CheckBoxComponent {...{ num, onClick, checkedState }} />
            <div {...{ className: pageStyles.phoneCell }}>{phoneNum}</div>
        </div>
    );
};

export const PhoneTable = ({ addToBuffer, phoneList = [] }) => {
    return (
        <div {...{ className: pageStyles.phoneTable }}>
            <div {...{ className: pageStyles.phoneTableHead }}>
                <div {...{ className: pageStyles.phoneTableHeadCheckbox }}></div>
                <div {...{ className: pageStyles.phoneTableHeadPhone }}>Номер телефона</div>
            </div>
            {phoneList.map((index, key) => {
                return !isNaN(index) && <PhoneRow {...{ key: index, addToBuffer, phoneNum: index, num: key }} />;
            })}
        </div>
    );
};

export const DeleteBlock = ({ phoneDeleteList = [], deletePhones }) => {
    return (
        <div {...{ className: pageStyles.deleteBlockWrap }}>
            <div {...{ className: pageStyles.DeleteBlockChosenText }}>Выбрано - {phoneDeleteList.length} </div>
            <button {...{ className: pageStyles.DeleteBlockButton }} onClick={() => deletePhones()}>
                удалить
            </button>
        </div>
    );
};
