import React, { Component } from 'react';
import Map from '@/components/maps/billboardsmap';
import styles from './styles.module.scss';

class BillboardsMap extends Component {
  constructor(props) {
    super(props);

    this.observer = new IntersectionObserver(this.observerCallback, {
      root: this.$map,
      threshold: 0.7,
    });
  }

  state = {
    mapIsObservered: false,
  };

  componentDidMount() {
    this.observer.observe(this.$map);
  }

  observerCallback = e => {
    if (e[0].isIntersecting && !this.state.mapIsObservered) {
      this.setState({ mapIsObservered: true });
      this.observer.unobserve(this.$map);
    }
  };

  render() {
    const { data, center, zoom } = this.props;

    return (
      <div className={styles.root} ref={ref => (this.$map = ref)}>
        {this.state.mapIsObservered && <Map data={data} center={center} zoom={zoom} />}
      </div>
    );
  }
}
export default BillboardsMap;
