const onChangePhone = (e, SetPhoneValue) => {
    SetPhoneValue(e.target.value);
};
const onChangePass = (e, SetPasswordValue) => {
    SetPasswordValue(e.target.value);
};

const changeAuthType = ({ mobileId, SetMobileId }) => SetMobileId(!mobileId);

const isPhoneValid = (phoneValue) => {
    const v = phoneValue.replace(/\D+/g, '');
    const phoneRegexp = /^\d{11}$/;
    return !!phoneRegexp.test(v);
};

const normalisePhones = (data) => data.map((key) => key.phone + '');

export { onChangePhone, onChangePass, changeAuthType, isPhoneValid, normalisePhones };
