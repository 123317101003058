import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Bar/styles.module.scss';

const BarBackground = ({ color }) => (
    <div className={cx(styles.bar)} style={{ background: color, opacity: 0.1, width: `100%` }} />
);

BarBackground.propTypes = {
    // Bar color
    color: PropTypes.string
};

export default BarBackground;
