import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '@/components/layout';
import SEO from '@/components/seo';
import NoSSR from '@/components/no-ssr';
import { Section } from '@/components/UI/Section';
import { StyledButton } from '@/components/UI/Button';
import LineChart from '@/components/Charts/LineChart';
import ChartHeader from '@/components/ChartHeader';

const PAGE = {
  pageTitle: 'Поиск потенциальных клиентов. Лидогенерация',
  sectionTitle: 'лидогенерация',
  pageDescription:
    'Поиск клиентов по&nbsp;вашей базе или по&nbsp;базе абонентов МегаФона на&nbsp;основе эталонной модели или поведенческих триггеров.',
  introPieChart: {
    value: 97,
    description:
      'Экономически активного населения&nbsp;РФ, не&nbsp;ограничиваясь абонентами мобильных операторов или пользователями интернета, участвует в&nbsp;исследовании.',
  },
  industries: ['Банки', 'Недвижимость', 'МФО', 'Продажа окон', 'Автодилеры'],
};

const CHART_HEADER = {
  title: 'Пользовательские запросы',
  description:
    'Лиды, выявленные для банков и&nbsp;МФО, проходят предварительный скоринг. Таким образом, кредитное предложение получает только одобренный потенциальный клиент.',
  labels: [
    { text: 'Ипотека', color: '#FDC73D' },
    { text: 'Потребительский кредит', color: '#FC8123' },
    { text: 'Страхование', color: '#EB5A40' },
  ],
};

const NewClientsPage = ({ data }) => (
  <Layout page={PAGE}>
    <SEO title={PAGE.pageTitle} />
    <Section>
      <StyledButton primary>Москва</StyledButton>
    </Section>
    <Section noPaddingTop>
      <ChartHeader {...CHART_HEADER} />
      <NoSSR>
        <LineChart
          data={[
            {
              type: 'страхование',
              items: data.insurance.edges.map(v => v.node),
              color: '#EB5A40',
            },
            {
              type: 'ипотека',
              items: data.mortgage.edges.map(v => v.node),
              color: '#FDC73D',
            },
            {
              type: 'потребительский кредит',
              items: data.credit.edges.map(v => v.node),
              color: '#FC8123',
            },
          ]}
        />
      </NoSSR>
    </Section>
  </Layout>
);

const query = graphql`
  query {
    mortgage: allLeadGenJson(
      filter: { weekday: { eq: "1" }, product_type: { eq: "ипотека" } }
      sort: { fields: [time], order: ASC }
    ) {
      edges {
        node {
          cnt
          weekday
          time
          product_type
        }
      }
    }
    insurance: allLeadGenJson(
      filter: { weekday: { eq: "1" }, product_type: { eq: "страхование" } }
      sort: { fields: [time], order: ASC }
    ) {
      edges {
        node {
          cnt
          weekday
          time
          product_type
        }
      }
    }
    credit: allLeadGenJson(
      filter: { weekday: { eq: "1" }, product_type: { eq: "потребительский кредит" } }
      sort: { fields: [time], order: ASC }
    ) {
      edges {
        node {
          cnt
          weekday
          time
          product_type
        }
      }
    }
  }
`;

export default () => <StaticQuery query={query} render={data => <NewClientsPage data={data} />} />;
