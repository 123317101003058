import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import cx from 'classnames';

const BG_SETS = [['#FEB123', '#FFD78C'], ['#00B956', '#10E272']];

class BarChart extends Component {
  constructor(props) {
    super(props);

    const { data } = this.props;

    const sum = data
      .map(v => v.value)
      .reduce((sum, cur) => {
        return sum + cur;
      });

    this.data = {
      maxValue: Math.max.apply(Math, data.map(v => v.value)),
      sum: sum,
      bars: [],
    };

    data.forEach(v => {
      this.data.bars.push({
        ...v,
        percentage: Math.round((100 / this.data.sum) * v.value),
        percentHeight: Math.round((100 / this.data.maxValue) * v.value),
      });
    });

    this.observer = new IntersectionObserver(this.observerCallback, {
      root: this.$root,
      threshold: 0.7,
    });
  }

  state = {
    graphIsMounted: false,
    barWidth: 0,
    hoveredCol: null,
  };

  componentDidMount() {
    this.setState({
      barWidth: this.getColSize() * 2,
    });
    this.observer.observe(this.$root);
    window.addEventListener('resize', this.handleResize);
  }

  observerCallback = e => {
    if (e[0].isIntersecting && !this.state.graphIsMounted) {
      this.setState({ graphIsMounted: true });
      this.observer.unobserve(this.$root);
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      barWidth: this.getColSize() * 2,
    });
  };

  getColSize = () => {
    return this.$grid
      ? this.props.horizontal
        ? this.$grid.getBoundingClientRect().height / 10
        : this.$grid.getBoundingClientRect().width / 10
      : null;
  };

  render() {
    const { horizontal, smallCaptions } = this.props;

    const gridArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

    return (
      <div className={styles.root} ref={ref => (this.$root = ref)}>
        <div
          className={horizontal ? styles.gridHorizontal : styles.grid}
          ref={ref => (this.$grid = ref)}
        >
          {gridArr.map((e, i) => (
            <div
              style={{
                background: this.state.hoveredCol === i ? '#F2F6F7' : 'transparent',
              }}
              className={horizontal ? styles.gridHorizontalLine : styles.gridLine}
              key={i}
            />
          ))}
        </div>
        <div className={horizontal ? styles.barsHorizontal : styles.bars}>
          {this.data.bars.map((b, i) => (
            <div
              style={{
                width: horizontal ? `${b.percentHeight}%` : this.state.barWidth,
                height: !horizontal ? `${b.percentHeight}%` : this.state.barWidth,
                marginLeft: !horizontal && i !== 0 && `-${this.state.barWidth / 2}px`,
                marginTop: horizontal && i !== 0 && `-${this.state.barWidth / 2}px`,
              }}
              className={horizontal ? styles.barHorizontal : styles.bar}
              key={i}
            >
              {!horizontal && (
                <>
                  <div
                    className={cx(styles.barTitleTop, this.state.graphIsMounted && '_show')}
                    style={{
                      transitionDelay: `${i * 0.2 + 0.3}s`,
                    }}
                  >
                    {`${b.percentage}%`}
                  </div>
                  <div
                    className={cx(styles.barTitleBottom, {
                      [styles.barTitleBottomSmall]: smallCaptions,
                    })}
                  >
                    {b.title}
                  </div>
                </>
              )}
              {horizontal && (
                <>
                  <div className={styles.barTitleLeft}>{b.title}</div>
                </>
              )}
              <div className={styles.barBg}>
                <div className={styles.barBgHidden}>
                  <div
                    className={cx(
                      styles.barBgInner,
                      this.state.graphIsMounted && '_show',
                      horizontal && '_horizontal'
                    )}
                    style={{
                      transitionDelay: `${i * 0.2}s`,
                      backgroundColor: horizontal
                        ? i % 2 === 0
                          ? BG_SETS[1][0]
                          : BG_SETS[1][1]
                        : i % 2 === 0
                        ? BG_SETS[0][0]
                        : BG_SETS[0][1],
                    }}
                  />
                </div>
                {horizontal && (
                  <div
                    className={cx(
                      styles.barTitleTop,
                      '_horizontal',
                      this.state.graphIsMounted && '_show'
                    )}
                    style={{
                      transitionDelay: `${i * 0.2 + 0.3}s`,
                    }}
                  >
                    {`${b.percentage}%`}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div
          className={horizontal ? styles.gridHorizontal : styles.grid}
          onMouseLeave={() => this.setState({ hoveredCol: null })}
        >
          {gridArr.map((e, i) => (
            <div
              onMouseOver={() => this.setState({ hoveredCol: i })}
              className={horizontal ? styles.gridHorizontalLinePseoudo : styles.gridLinePseoudo}
              key={i}
            />
          ))}
        </div>
      </div>
    );
  }
}

BarChart.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  horizontal: PropTypes.bool,
};

export default BarChart;
