import React from 'react';
import styles from './styles.module.scss';

import demography from '@/components/BigDataMegafon/Profiling/imgs/demography.png';
import style from '@/components/BigDataMegafon/Profiling/imgs/style.png';
import incomeLevel from '@/components/BigDataMegafon/Profiling/imgs/incomeLevel.png';
import type from '@/components/BigDataMegafon/Profiling/imgs/type.png';
import mobile from '@/components/BigDataMegafon/Profiling/imgs/mobile.png';
import travel from '@/components/BigDataMegafon/Profiling/imgs/travel.png';
import digital from '@/components/BigDataMegafon/Profiling/imgs/digital.png';
import interest from '@/components/BigDataMegafon/Profiling/imgs/interest.png';
import realty from '@/components/BigDataMegafon/Profiling/imgs/realty.png';

const CELL_DATA = [
    { img: <img {...{ src: demography }} />, name: 'Демография', text: 'Пол, возраст' },
    { img: <img {...{ src: style }} />, name: 'Стиль жизни', text: 'Семейное положение, наличие детей' },
    { img: <img {...{ src: incomeLevel }} />, name: 'Уровень доходов', text: 'Уровень доходов, расходов' },
    {
        img: <img {...{ src: type }} />,
        name: 'Тип занятости',
        text: 'Наличие работы, вид занятости (офисная/разъездная)'
    },
    {
        img: <img {...{ src: mobile }} />,
        name: 'Мобильность',
        text: 'Пробег, наличие авто, виды транспорта, используемые регулярно'
    },
    { img: <img {...{ src: travel }} />, name: 'Путешествия', text: 'Цели поездок, регулярность, направления' },
    { img: <img {...{ src: digital }} />, name: 'Digital', text: 'Устройство, поведение в интернете' },
    { img: <img {...{ src: interest }} />, name: 'Интересы', text: 'Лояльность к брендам, склонность к покупкам' },
    {
        img: <img {...{ src: realty }} />,
        name: 'Недвижимость',
        text: 'Ищет квартиру, имеет собственную квартиру, имеет несколько квартир'
    }
];

export const Profiling = () => {
    return (
        <div {...{ className: styles.wrapper }}>
            {CELL_DATA.map((el, index) => {
                return (
                    <div {...{ key: index, className: styles.cell }}>
                        <div {...{ className: styles.cellImg }}>{el.img}</div>
                        <div>
                            <div {...{ className: styles.cellHead }}>{el.name}</div>
                            <div {...{ className: styles.cellText }}>{el.text}</div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
