import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@/components/UI/Button';
import IconPlus from '@/components/UI/IconPlus';
import styles from './styles.module.scss';

class ScoringButtonMore extends Component {
    render() {
        const { isOpen, onClick } = this.props;

        return (
            <Button className={styles.moreButton} onClick={onClick}>
                <span>{isOpen ? 'свернуть' : 'развернуть'}</span>
                <IconPlus reverse={isOpen} />
            </Button>
        );
    }
}

ScoringButtonMore.propTypes = {
    isOpen: PropTypes.bool.isRequired
};

export default ScoringButtonMore;
