import React, { Component } from 'react';
import ServicesPage from '@/components/Pages/ServicesPage';
import Header from '@/components/Header';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-WZT6KWQ'
};

const LINKS = [
    {
        path: '/app/portrait',
        title: 'Построение портрета покупателя',
        img: require('@/images/main_image_1.jpg'),
        classKey: 'portrait'
    },
    {
        path: '/app/scoring',
        title: 'Рисковый скоринг',
        img: require('@/images/main_image_2.jpg'),
        classKey: 'scoring'
    },
    // {
    //   path: '/app/locations',
    //   title: 'Поиск оптимальных локаций',
    //   img: require('@/images/main_image_3.jpg'),
    //   classKey: 'locations',
    // },
    {
        path: '/app/billboards',
        title: 'Оптимизация размещения рекламы',
        img: require('@/images/main_image_4.jpg'),
        classKey: 'billboards'
    },
    {
        path: '/app/new-clients',
        title: 'Лидогенерация',
        img: require('@/images/main_image_5.jpg'),
        classKey: 'leads'
    },
    {
        url: 'https://bdtour.megafon.ru/login',
        title: 'Цифровой туризм',
        img: require('@/images/main_image_6.jpg'),
        classKey: 'bigData'
    },
    {
        path: '/app/covid',
        title: 'COVID-2019',
        img: require('@/images/main_image_7.jpg'),
        classKey: 'covid'
    },
    {
        path: '/app/bd-megafon',
        title: 'Большие данные и МегаФон',
        img: require('@/images/bd-ico.jpg'),
        classKey: 'bdMegafon'
    }
];

export default class Dashboard extends Component {
    componentDidMount() {
        TagManager.initialize(tagManagerArgs);
    }

    render() {
        return (
            <>
                <Header isIndexPage />
                <ServicesPage links={LINKS} />
            </>
        );
    }
}
