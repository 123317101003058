import React, { Component } from 'react';
import Heatmap from '@/components/maps/heatmap';
import styles from './styles.module.scss';

function getWeight(visitors) {
  return visitors > 500 ? 3 : visitors > 100 ? 2 : 1;
}

class VisitorsMap extends Component {
  constructor(props) {
    super(props);

    this.observer = new IntersectionObserver(this.observerCallback, {
      root: this.$map,
      threshold: 0.7,
    });
  }

  state = {
    mapIsObservered: false,
  };

  zoomIn = () => {
    const { map } = this.state;
    this.setState({
      zoom: map.getZoom() + 1,
    });
  };

  zoomOut = () => {
    const { map } = this.state;
    this.setState({
      zoom: map.getZoom() - 1,
    });
  };

  componentDidMount() {
    this.observer.observe(this.$map);
  }

  observerCallback = e => {
    if (e[0].isIntersecting && !this.state.mapIsObservered) {
      this.setState({ mapIsObservered: true });
      this.observer.unobserve(this.$map);
    }
  };

  handleMapMounted = map => {
    this.setState({ map });
  };

  handleZoomChanged = e => {
    const { map } = this.state;
    this.setState({
      zoom: map.getZoom(),
    });
  };

  render() {
    const { data } = this.props;
    const { zoom } = this.state;

    return (
      <div className={styles.map} ref={ref => (this.$map = ref)}>
        {this.state.mapIsObservered && (
          <Heatmap
            onZoomChanged={this.handleZoomChanged}
            onMapMounted={this.handleMapMounted}
            center={{ lat: 55.775028249999998, lng: 37.603454589999998 }}
            data={data}
            getWeight={getWeight}
            zoom={zoom}
            zoomIn={this.zoomIn}
            zoomOut={this.zoomOut}
            valueKey="visitors"
          />
        )}
      </div>
    );
  }
}

export default VisitorsMap;
