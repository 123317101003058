import PropTypes from 'prop-types';
import React from 'react';

import { screens } from '@/components/utils/mediaQuery';
import viewport from '@/components/utils/viewport';
import { CREDIT_SCORE_NAME, SUCCESS_STATUS } from '../../consts';
import ScoringResultDetailedLine from './ScoringResultDetailedLine';
import styles from './styles.module.scss';

const ScoringResultDetailed = ({ data }) => (
    <div className={styles.detailed}>
        {Object.keys(data)
            .filter((varKey) => data[varKey].status === SUCCESS_STATUS && data[varKey].name !== CREDIT_SCORE_NAME)
            .sort((k1, k2) =>
                viewport.width < screens.desktop
                    ? data[k1].altOrder > data[k2].altOrder
                        ? 1
                        : -1
                    : data[k1].order > data[k2].order
                    ? 1
                    : -1
            )
            .map((varKey, i) => (
                <ScoringResultDetailedLine key={i} {...data[varKey]} />
            ))}
    </div>
);
export default ScoringResultDetailed;

ScoringResultDetailed.propTypes = {
    data: PropTypes.object
};
