import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChartLabels from '@/components/UI/ChartLabels';
import styles from './styles.module.scss';
import { Button } from '@/components/UI/Button';
import IconPlus from '@/components/UI/IconPlus';
import CollapseTransition from '@/components/UI/CollapseTransition';

class ChartHeader extends Component {
    state = {
        isDescriptionOpen: true
    };

    toggleDescription = () => {
        this.setState({ isDescriptionOpen: !this.state.isDescriptionOpen });
    };

    render() {
        const { title, description, labels } = this.props;
        const { isDescriptionOpen } = this.state;

        return (
            <div className={styles.root}>
                <div className={styles.title}>
                    <div className={'H3'}>{title}</div>
                    {description && (
                        <Button className={styles.descButton} onClick={this.toggleDescription}>
                            {isDescriptionOpen ? 'скрыть' : 'описание'}
                            <IconPlus reverse={isDescriptionOpen} position={'right'} />
                        </Button>
                    )}
                </div>
                {description && (
                    <CollapseTransition in={isDescriptionOpen}>
                        <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
                    </CollapseTransition>
                )}
                {labels && (
                    <div className={styles.labels}>
                        <ChartLabels labels={labels} />
                    </div>
                )}
            </div>
        );
    }
}

export default ChartHeader;

ChartHeader.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    labels: PropTypes.array
};
