import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import SEO from '@/components/seo';
import NoSSR from '@/components/no-ssr';
import Layout from '@/components/layout';
import { Section } from '@/components/UI/Section';
import ChartHeader from '@/components/ChartHeader';
import ForecastsMap from '@/components/ForecastsMap';

const PAGE = {
  pageTitle: 'Поиск оптимальных локаций',
  sectionTitle: 'поиск оптимальных локаций',
  pageDescription:
    'Поиск локаций по&nbsp;различным критериям позволит вам найти неочевидные локации и&nbsp;увеличить долю на&nbsp;рынке.',
  introPieChart: {
    value: 80,
    prefix: 'до',
    description: 'Снижение временных затрат на выбор новой локаций',
  },
};

const CHART_HEADER = {
  title: 'Объем товарооборота в месяц',
  description:
    'Сервис работает на&nbsp;территории всей&nbsp;РФ, при этом точность гео-данных достигает 50&nbsp;м. Снизьте риски при открытии новой точки и&nbsp;повысьте выручку в&nbsp;существующих локациях.',
  labels: [
    { text: '< 3.2 млн. руб', color: '#FDD77D' },
    { text: '3.2–3.3 млн. руб', color: '#FFAC4B' },
    { text: '3.3–3.5 млн. руб', color: '#FF8D40' },
    { text: '> 3.5 млн. руб', color: '#FF4844' },
  ],
};

const LocationsPage = ({ data }) => (
  <Layout page={PAGE}>
    <SEO title={PAGE.pageTitle} />
    <Section />
    <Section>
      <ChartHeader {...CHART_HEADER} />
      <NoSSR>
        <ForecastsMap
          data={data.forecasts.edges.map(v => v.node)}
          center={{ lat: 56.836987, lng: 60.63449 }}
          defaultZoom={11}
        />
      </NoSSR>
    </Section>
  </Layout>
);

const query = graphql`
  query {
    forecasts: allForecastJson {
      edges {
        node {
          lat
          lng
          forecast
        }
      }
    }
  }
`;

export default () => <StaticQuery query={query} render={data => <LocationsPage data={data} />} />;
