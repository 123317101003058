import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import cx from 'classnames';
import { colors, riskThresholdCOVID } from '@/consts';

import ProgressBar from '@/components/ProgressBar';

const getColorByValue = (value) => {
    if (value === null) return undefined;

    if (value > riskThresholdCOVID.danger) return colors.red;
    return colors.green;
};

const getLabelByValue = ({ isLoading, hasError, value = null }) => {
    if (hasError) return 'Ошибка получения данных';
    if (isLoading) return 'Загрузка данных...';
    if (value === null) return '';

    if (value > riskThresholdCOVID.danger) return 'Подозрение на инфицирование, рекомендуется проверка';
    return 'подозрения на инфицирование нет';
};

const ScoringResult = ({ result, isLoading, hasError }) => {
    const [color, setColor] = useState(undefined);
    const [label, setLabel] = useState('');
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (hasError || isLoading || result === null) {
            setColor(undefined);
            setLabel(getLabelByValue({ isLoading, hasError }));
            setValue(null);

            return;
        }

        const _value = Number.parseFloat(result);

        setColor(getColorByValue(_value));
        setLabel(getLabelByValue({ isLoading, hasError, value: _value }));
        setValue(_value);
    }, [hasError, isLoading, result]);

    return (
        <div className={cx(styles.field, !result && styles.disabled)}>
            <div className={cx(styles.fieldTitle, 'H3')}>Риск</div>
            <ProgressBar {...{ color, label, value }} />
        </div>
    );
};

ScoringResult.propTypes = {
    hasError: PropTypes.bool,
    isLoading: PropTypes.bool,
    // Risk value
    result: PropTypes.number
};

export default ScoringResult;
