import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import SEO from '@/components/seo';
import NoSSR from '@/components/no-ssr';
import Layout from '@/components/layout';
import { Section, SectionTitle, GraphsWrapper } from '@/components/UI/Section';
import { StyledButton } from '@/components/UI/Button';
import ChartWrapper from '@/components/ChartWrapper';
import VisitorsMap from '@/components/VisitorsMap';
import ChartHeader from '@/components/ChartHeader';
import BarChart from '@/components/Charts/BarChart';
import TwoSectionPieChart from '@/components/Charts/TwoSectionPieChart';

const PAGE = {
  pageTitle: 'Построение портрета покупателя',
  sectionTitle: 'построение портрета покупателя',
  pageDescription:
    'Сервис оценки ключевых характеристик вашего клиента, построенный на&nbsp;основе анализа больших данных. <br/><br/>Сервис &laquo;Портрет покупателя&raquo; позволит вам изучить своих клиентов, узнать об&nbsp;их&nbsp;особенностях и&nbsp;потребностях.',
  introPieChart: {
    value: 97,
    description:
      'Экономически активного населения&nbsp;РФ, не&nbsp;ограничиваясь абонентами мобильных операторов или пользователями интернета, участвует в&nbsp;исследовании.',
  },
  relativeServices: {
    title: 'Дополнительные услуги клиентской аналитики:',
    list: [
      {
        path: '/',
        title: 'Сегментация <br/>клиентской базы',
        text:
          'Определение принадлежности ваших клиентов к&nbsp;поведенческим сегментам любой сложности.',
        icon: 'segment',
      },
      {
        path: '/',
        title: 'Мониторинг <br/>клиентской базы',
        text:
          'Отслеживание совершения клиентами целевого события для своевременного формирования персонального предложения.',
        icon: 'monitor',
      },
    ],
  },
};

const MAP_GRAPH_HEADER = {
  title: 'Количество человек',
  description:
    'Тепловая карта может быть построена с&nbsp;детализацией до&nbsp;квадратов 100*100&nbsp;м по&nbsp;местам проживания, работы и&nbsp;другим часто посещаемым вашими клиентами локациям.',
  labels: [
    { text: '< 100', color: '#FDC73D' },
    { text: '100 - 500', color: '#FC8123' },
    { text: '> 500', color: '#EB5A40' },
  ],
};

/*
const CHART_OPTIONS = [
  {
    text: 'январь',
    value: '01',
  },
  {
    text: 'февраль',
    value: '02',
  },
  {
    text: 'март',
    value: '03',
  },
  {
    text: 'апрель',
    value: '04',
  },
  {
    text: 'май',
    value: '05',
  },
  {
    text: 'июнь',
    value: '06',
  },
  {
    text: 'июль',
    value: '07',
  },
  {
    text: 'август',
    value: '08',
  },
  {
    text: 'сентябрь',
    value: '09',
  },
  {
    text: 'октябрь',
    value: '10',
  },
  {
    text: 'ноябрь',
    value: '11',
  },
  {
    text: 'декабрь',
    value: '12',
  },
];
*/

const getTotalCount = (arr, param) => {
  let result = 0;

  arr.map(v => {
    return (result = result + parseFloat(v[param]));
  });

  return result;
};

const PortraitPage = ({ data }) => {
  // const [chart1Value, setChart1Value] = useState({ text: 'март', value: '03' });

  return (
    <Layout page={PAGE}>
      <SEO title={PAGE.pageTitle} />

      <Section>
        <StyledButton primary>БЦ Оружейный</StyledButton>
      </Section>

      <Section noPaddingTop>
        <SectionTitle>Социальные характеристики</SectionTitle>
        <GraphsWrapper>
          <ChartWrapper
            title={'Возраст'}
            desc={'лет'}
            // options={CHART_OPTIONS}
            // selectedOption={chart1Value}
            // onOptionSelect={(e) => setChart1Value(e)}
          >
            <BarChart
              data={[
                {
                  title: '18-24',
                  value: getTotalCount(data.age.edges.map(v => v.node), '_18_24'),
                },
                {
                  title: '25-34',
                  value: getTotalCount(data.age.edges.map(v => v.node), '_35_44'),
                },
                {
                  title: '35-44',
                  value: getTotalCount(data.age.edges.map(v => v.node), '_45_54'),
                },
                {
                  title: '45-54',
                  value: getTotalCount(data.age.edges.map(v => v.node), '_55_64'),
                },
                {
                  title: '55-64',
                  value: getTotalCount(data.age.edges.map(v => v.node), '_65_'),
                },
              ]}
            />
          </ChartWrapper>
          <ChartWrapper title={'Пол'}>
            <TwoSectionPieChart
              data={[
                {
                  title: 'Женщины',
                  value: getTotalCount(data.gender.edges.map(v => v.node), 'Women'),
                },
                {
                  title: 'Мужчины',
                  value: getTotalCount(data.gender.edges.map(v => v.node), 'Men'),
                },
              ]}
            />
          </ChartWrapper>
          <ChartWrapper title={'Доход'} desc={'тыс. руб/мес'}>
            <BarChart
              smallCaptions
              data={[
                {
                  title: 'Низкий',
                  value: getTotalCount(data.income.edges.map(v => v.node), 'Low_income'),
                },
                {
                  title: 'Средний',
                  value: getTotalCount(data.income.edges.map(v => v.node), 'Average_income'),
                },
                {
                  title: 'Выше среднего',
                  value: getTotalCount(data.income.edges.map(v => v.node), 'Above_average_income'),
                },
              ]}
            />
          </ChartWrapper>
          <ChartWrapper title={'Род занятости'}>
            <BarChart
              data={[
                {
                  title: 'Автомобилисты',
                  value: getTotalCount(data.job.edges.map(v => v.node), 'Drivers'),
                },
                {
                  title: 'Офисные работники',
                  value: getTotalCount(data.job.edges.map(v => v.node), 'Office_workers'),
                },
                {
                  title: 'Домохозяйки',
                  value: getTotalCount(data.job.edges.map(v => v.node), 'Housekeepers'),
                },
                {
                  title: 'Cтуденты',
                  value: getTotalCount(data.job.edges.map(v => v.node), 'Students'),
                },
              ]}
              horizontal
            />
          </ChartWrapper>
          <ChartWrapper title={'Путешестия'}>
            <TwoSectionPieChart
              data={[
                       {
                  title: 'За рубежом',
                  value: getTotalCount(data.travel.edges.map(v => v.node), 'Travellers_across_Russia'),
                },
                {
                  title: 'По России',
                  value: getTotalCount(
                    data.travel.edges.map(v => v.node),
                    'Abroad_travellers'
                  ),
                },
       
              ]}
              invertTooltipsYOffset
            />
          </ChartWrapper>
        </GraphsWrapper>
      </Section>

      <Section noPaddingTop>
        <SectionTitle>Распределение посетителей по местам проживания</SectionTitle>
        <ChartHeader {...MAP_GRAPH_HEADER} />
        <NoSSR>
          <VisitorsMap
            data={data.map.edges.map(v => v.node)}
            center={{ lat: 55.775028249999998, lng: 37.603454589999998 }}
          />
        </NoSSR>
      </Section>
    </Layout>
  );
};

const query = graphql`
  query {
    map: allVisitorsFromJson {
      edges {
        node {
          lat
          lng
          visitors
        }
      }
    }
    job: allVisitorsJson {
      edges {
        node {
          Drivers
          Students
          Office_workers
          Housekeepers
        }
      }
    }
    income: allVisitorsJson {
      edges {
        node {
          Low_income
          Average_income
          Above_average_income
        }
      }
    }
    gender: allVisitorsJson {
      edges {
        node {
          Women
          Men
        }
      }
    }
    travel: allVisitorsJson {
      edges {
        node {
          Abroad_travellers
          Travellers_across_Russia
        }
      }
    }
    age: allVisitorsJson {
      edges {
        node {
          _18_24
          _35_44
          _45_54
          _55_64
          _65_
        }
      }
    }
  }
`;

export default () => <StaticQuery query={query} render={data => <PortraitPage data={data} />} />;
