import React, { useCallback, useContext, useState, useEffect } from 'react';
import pageStyles from './pageStyles.module.scss';
import headerStyles from '@/components/Header/styles.module.scss';
import { StyledButton } from '@/components/UI/Button';
import { AuthContext } from '@/components/AuthContext';
import { logout } from '@/services/auth';
import { Link } from 'gatsby';
import ArrowBack from '@/assets/icons/arrow-back.svg';
import axios from 'axios';
import InputMask from 'react-input-mask';

import { onChangePhone, onChangePass, changeAuthType, isPhoneValid, normalisePhones } from './utils';
import { PhoneTable, DeleteBlock } from './units';

const AddPhone = () => {
    const [isLoggedIn, setIsLoggedIn] = useContext(AuthContext);

    const [phoneDeleteList, SetDeletePhoneList] = useState([]);
    const [phoneList, SetPhoneList] = useState([]);

    const [phoneValue, SetPhoneValue] = useState('');
    const [mobileId, SetMobileId] = useState(true);
    const [passwordValue, SetPasswordValue] = useState('');
    const [phoneValidation, SetPhoneValidation] = useState(false);
    const [success, SetSuccess] = useState(false);

    useEffect(() => {
        getPhones();
    }, []);

    useEffect(() => {
        isPhoneValid(phoneValue) ? SetPhoneValidation(true) : SetPhoneValidation(false);

        SetSuccess(false);
    }, [phoneValue]);

    const handleLogout = useCallback(async (e) => {
        e.preventDefault();
        await logout();
        setIsLoggedIn(false);
    });

    const clearAllHandle = () => {
        SetPhoneValue('');
        SetMobileId(true);
        SetPasswordValue('');
        SetSuccess(false);
    };

    const getPhones = async () => {
        await axios
            .get('/bigdata-back/api/phone', {
                //.get('https://bigdata.demo.megafon.ru/bigdata-back/api/phone', {
                // dev API endpoint
                data: {}
            })
            .then((result) => {
                const { data } = result;
                SetPhoneList(normalisePhones(data));
                SetSuccess(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const deletePhones = async () => {
        await axios
            .delete('/bigdata-back/api/phone', {
                //.delete('https://bigdata.demo.megafon.ru/bigdata-back/api/phone', {
                data: { phones: phoneDeleteList }
            })
            .then(() => {
                getPhones();
                SetDeletePhoneList([]);
                SetSuccess(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const addPhoneByGet = async (e) => {
        e.preventDefault();

        phoneValidation &&
            (await axios
                .post('/bigdata-back/api/phone', {
                    //.post('https://bigdata.demo.megafon.ru/bigdata-back/api/phone', {
                    // dev API endpoint

                    phone: phoneValue.replace(/[^\d.]/gi, ''),
                    mobileid: mobileId ? '1' : '0',
                    password: passwordValue
                })
                .then(() => {
                    getPhones();
                    SetSuccess(true);
                })
                .catch((err) => {
                    console.log(err);
                }));
    };

    const addToBuffer = ({ phoneNum }) => {
        let deleteBuffer = phoneDeleteList;

        deleteBuffer.includes(phoneNum)
            ? (deleteBuffer = deleteBuffer.filter((item) => item !== phoneNum))
            : deleteBuffer.push(phoneNum);

        SetDeletePhoneList(deleteBuffer.slice());
    };

    return (
        <>
            <div className={pageStyles.header}>
                <div className={pageStyles.headerButtonsWrap}>
                    <div className={headerStyles.breadcrumbs}>
                        <Link className={headerStyles.home} to={'/app'}>
                            <ArrowBack className={headerStyles.homeIcon} />
                            главная / добавление пользователей
                        </Link>
                    </div>
                    <div className={headerStyles.buttons}>
                        <StyledButton noBorder md onClick={handleLogout}>
                            Выйти
                        </StyledButton>
                    </div>
                </div>
                <div className={pageStyles.headerTitle}>Добавление пользователей</div>
            </div>
            <DeleteBlock {...{ deletePhones, phoneDeleteList }} />
            <div className={pageStyles.content}>
                <PhoneTable {...{ addToBuffer, phoneList }} />
                <form className={pageStyles.form}>
                    <div className={pageStyles.inputBox}>
                        <div onClick={() => changeAuthType({ mobileId, SetMobileId })}>
                            Выбрать способ авторизации: <strong>{mobileId ? 'MobileId' : 'Пароль'}</strong>
                        </div>
                    </div>

                    <div className={pageStyles.inputBox}>
                        <InputMask
                            autoFocus
                            type={'text'}
                            className={pageStyles.inputStyle}
                            placeholder={'Номер телефона'}
                            mask="+7 (999) 999-99-99"
                            value={phoneValue}
                            onChange={(e) => onChangePhone(e, SetPhoneValue)}
                        />
                    </div>

                    {!mobileId && (
                        <div className={pageStyles.inputBox}>
                            <InputMask
                                type={'text'}
                                className={pageStyles.inputStyle}
                                placeholder={'Пароль'}
                                value={passwordValue}
                                onChange={(e) => onChangePass(e, SetPasswordValue)}
                            />
                        </div>
                    )}
                    <div className={pageStyles.buttonBox}>
                        <button onClick={addPhoneByGet} className={pageStyles.submitButton}>
                            Добавить
                        </button>
                    </div>
                    {success && (
                        <>
                            <div>Телефон записан в базу</div>
                            <div onClick={clearAllHandle} className={pageStyles.clearAll}>
                                <strong>Записать еще?</strong>
                            </div>
                        </>
                    )}
                </form>
            </div>
        </>
    );
};

export default AddPhone;
