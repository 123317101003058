import React, { Component } from 'react';

import Heatmap from '@/components/maps/heatmap';

import { HEATMAP_MAX_ZOOM } from '../../consts';
import styles from './styles.module.scss';

function getWeight(forecast) {
    return forecast > 3500 ? 3 : forecast > 3300 ? 2 : forecast > 3200 ? 1 : 0.5;
}

class LocationsPage extends Component {
    constructor(props) {
        super(props);

        this.observer = new IntersectionObserver(this.observerCallback, {
            root: this.$map,
            threshold: 0.7
        });
    }

    state = {
        mapIsObservered: false,
        mapRef: null,
        zoom: this.props.defaultZoom
    };

    componentDidMount() {
        this.observer.observe(this.$map);
    }

    observerCallback = (e) => {
        if (e[0].isIntersecting && !this.state.mapIsObservered) {
            this.setState({ mapIsObservered: true });
            this.observer.unobserve(this.$map);
        }
    };

    zoomIn = () => {
        const { map } = this.state;
        const currentZoom = map.getZoom();
        if (currentZoom < HEATMAP_MAX_ZOOM) {
            this.setState({
                zoom: map.getZoom() + 1
            });
        }
    };

    zoomOut = () => {
        const { map } = this.state;
        this.setState({
            zoom: map.getZoom() - 1
        });
    };

    handleZoomChanged = (e) => {
        const { map } = this.state;
        this.setState({
            zoom: map.getZoom()
        });
    };

    handleMapMounted = (map) => {
        this.setState({ map });
    };

    getSettings = (zoom) => {
        // manually selected values
        // to make the heatmap look nicer
        if (zoom < 12) {
            return [30, 30];
        } else if (zoom < 13) {
            return [40, 15];
        } else if (zoom < 14) {
            return [60, 9];
        } else if (zoom < 15) {
            return [90, 4];
        }
    };

    render() {
        const { data, center, defaultZoom } = this.props;
        const { zoom } = this.state;

        const [radius, maxIntensity] = this.getSettings(zoom);

        return (
            <div className={styles.map} ref={(ref) => (this.$map = ref)}>
                {this.state.mapIsObservered && (
                    <Heatmap
                        onZoomChanged={this.handleZoomChanged}
                        onMapMounted={this.handleMapMounted}
                        center={center}
                        data={data}
                        getWeight={getWeight}
                        radius={radius}
                        maxIntensity={maxIntensity}
                        defaultZoom={defaultZoom}
                        zoom={zoom}
                        zoomIn={this.zoomIn}
                        zoomOut={this.zoomOut}
                        units="млн. руб"
                        unitDivider={1000}
                        valueKey="forecast"
                        showHint
                    />
                )}
            </div>
        );
    }
}

export default LocationsPage;
