import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import viewport from './utils/viewport';
import Header from './Header/';
import Footer from './Footer/';
import Intro from './Intro/';
import '@/styles/main.scss';

const Layout = ({ children, page }) => (
    <StaticQuery
        query={graphql`
            query SiteTitleQuery {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `}
        render={(data) => (
            <>
                {page && (
                    <>
                        <Header
                            siteTitle={data.site.siteMetadata.title}
                            currentPageTitle={page.sectionTitle || page.pageTitle}
                        />
                        <Intro
                            title={page.pageTitle}
                            description={page.pageDescription}
                            pieChart={page.introPieChart}
                            bigTitle={page.introBigTitle}
                            branches={page.introBranches}
                            introRight={page.introRight}
                            industries={page.industries}
                            onScrollDownButtonClick={() => {
                                viewport.scrollTo({ y: document.querySelector('#content').offsetTop });
                            }}
                        />
                        <div id="content">
                            <main>{children}</main>
                        </div>
                        <Footer
                            links={page.relativeServices}
                            onTopButtonClick={() => {
                                viewport.scrollTo({ y: 0 });
                            }}
                        />
                    </>
                )}
            </>
        )}
    />
);

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
