import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chart from '../Chart.js';
import styles from './styles.module.scss';
import viewport from '@/components//utils/viewport';
import { screens } from '@/components//utils/mediaQuery';

class LineChart extends Component {
  constructor(props) {
    super(props);

    const { data } = this.props;
    this.avalibleXLabels = [];

    if (viewport.width < screens.tablet) {
      this.avalibleXLabels = ['06:00', '11:00', '16:00', '21:00', '6:00'];
    } else {
      this.avalibleXLabels = [
        '00:00',
        '03:00',
        '06:00',
        '09:00',
        '12:00',
        '15:00',
        '18:00',
        '21:00',
        '00:00',
        '03:00',
        '0:00',
        '3:00',
        '6:00',
        '9:00',
        '12:00',
        '15:00',
        '18:00',
        '21:00',
        '0:00',
        '3:00',
      ];
    }

    const dataset = [];
    data.map(item => {
      return dataset.push({
        data: item.items.map(v => v.cnt),
        backgroundColor: item.color,
        borderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        fill: true,
        skipGap: true,
      });
    });

    const xLables = data[0].items.map(item => item.time);

    this.config = {
      type: 'line',
      backgroundColor: '#F5DEB3',
      data: {
        labels: xLables,
        datasets: dataset,
      },
      options: {
        hover: {
          mode: 'index',
          intersect: false,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          custom: tooltipModel => {
            if (tooltipModel.opacity === 0) {
              this.$line.style.opacity = 0;
              this.hover = null;
              return;
            }

            if (tooltipModel.dataPoints[0]) {
              this.$line0.innerHTML = tooltipModel.dataPoints[0].value;
              this.$line0.style.top = tooltipModel.dataPoints[0].y + 'px';
            }
            if (tooltipModel.dataPoints[1]) {
              this.$line1.innerHTML = tooltipModel.dataPoints[1].value;
              this.$line1.style.top = tooltipModel.dataPoints[1].y + 'px';
            }
            if (tooltipModel.dataPoints[2]) {
              this.$line2.innerHTML = tooltipModel.dataPoints[2].value;
              this.$line2.style.top = tooltipModel.dataPoints[2].y + 'px';
            }

            this.$line.style.opacity = 1;
            this.$line.style.left = tooltipModel.caretX + 'px';
          },
        },
        scales: {
          xAxes: [
            {
              display: false,
              ticks: {
                callback: (label, index, labels) => {
                  this.setState({ xLabels: labels });
                  return label;
                },
              },
            },
          ],
          yAxes: [
            {
              display: false,
              ticks: {
                // min: 0,
                // max: 2400,
                // stepSize: 600,
                callback: (label, index, labels) => {
                  this.setState({ yLabels: labels });
                  return;
                },
              },
            },
          ],
        },
      },
    };

    this.observer = new IntersectionObserver(this.observerCallback, {
      root: this.$root,
      threshold: 0.7,
    });
  }

  state = {
    graphIsMounted: false,
    yLabels: null,
    xLabels: null,
  };

  componentDidMount() {
    this.observer.observe(this.$root);
  }

  observerCallback = e => {
    if (e[0].isIntersecting && !this.state.graphIsMounted) {
      this.chart = new Chart(this.$chart, this.config);
      this.setState({ graphIsMounted: true });
      this.observer.unobserve(this.$root);
    }
  };

  render() {
    return (
      <div className={styles.root} ref={ref => (this.$root = ref)}>
        <div className={styles.inner}>
          <canvas className={styles.canvas} ref={ref => (this.$chart = ref)} />
          <div className={styles.line} ref={ref => (this.$line = ref)}>
            <div className={styles.lineLabel} ref={ref => (this.$line0 = ref)}></div>
            <div className={styles.lineLabel} ref={ref => (this.$line1 = ref)}></div>
            <div className={styles.lineLabel} ref={ref => (this.$line2 = ref)}></div>
          </div>
          <div className={styles.labelsY}>
            {this.state.yLabels &&
              this.state.yLabels.map((label, i) => (
                <div
                  className={
                    i === 0 || i === this.state.yLabels.length - 1
                      ? styles.labelYHidden
                      : styles.labelY
                  }
                  key={i}
                >
                  {label}
                </div>
              ))}
          </div>
        </div>
        <div className={styles.labelsX}>
          {this.state.xLabels &&
            this.state.xLabels.map((label, i) => (
              <div
                data-label={label}
                className={
                  this.avalibleXLabels.indexOf(label) > -1 ? styles.labelX : styles.labelXHidden
                }
                key={i}
              >
                <div className={styles.labelXInner}>{label}</div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

LineChart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default LineChart;
