import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CollapseTransition from '@/components/UI/CollapseTransition';
import { Button } from '@/components/UI/Button';
import IconPlus from '@/components/UI/IconPlus';
import styles from './styles.module.scss';

const IntroDescription = ({ text }) => {
    const [isOpen, toggleState] = useState(true);

    return (
        <div>
            <div className={styles.buttonWrap}>
                <Button onClick={() => toggleState(!isOpen)}>
                    <IconPlus reverse={isOpen} position={'left'} />
                    {isOpen ? 'свернуть описание' : 'развернуть описание'}
                </Button>
            </div>
            <CollapseTransition in={isOpen}>
                <div>
                    <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
                </div>
            </CollapseTransition>
        </div>
    );
};

export default IntroDescription;

IntroDescription.propTypes = {
    text: PropTypes.string.isRequired
};
