import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, withHandlers, withState } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import NavButton from '@/components/UI/NavButton';
import styles from './styles.module.scss';

const MARKER_COLORS = {
  bbb: '#731982',
  bba: '#00B956',
  cfb: '#FEB123',
};
const markerIcon = ({ type, size }) => {
  return {
    path: `M-${size / 2},0a${size / 2},${size / 2} 0 1,0 ${size},0a${size / 2},${size /
      2} 0 1,0 -${size},0`,
    fillColor: MARKER_COLORS[type],
    fillOpacity: 1,
    strokeWeight: 0,
    scale: 1,
  };
};

const Billboardsmap = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBCWWvu9kgXa7a0vun2iDO7kK1nY-mPXE4&v=3.exp&libraries=visualization',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withState('zoom', 'onZoomChange', props => {
    return props.zoom;
  }),
  withHandlers(() => {
    const refs = {
      map: undefined,
    };

    return {
      onMapMounted: () => ref => {
        refs.map = ref;
      },
      changeZoom: props => type => {
        if (type === 'in') return props.onZoomChange(refs.map.getZoom() + 1);
        if (type === 'out') return props.onZoomChange(refs.map.getZoom() - 1);
        return null;
      },
    };
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  return (
    <>
      <GoogleMap
        ref={props.onMapMounted}
        onZoomChanged={props.onZoomChanged}
        defaultZoom={10}
        zoom={props.zoom}
        defaultCenter={props.center}
        defaultOptions={{
          disableDefaultUI: true,
          styles: [
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#e9e9e9',
                },
                {
                  lightness: 17,
                },
              ],
            },
            {
              featureType: 'landscape',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#f5f5f5',
                },
                {
                  lightness: 20,
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#ffffff',
                },
                {
                  lightness: 17,
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#ffffff',
                },
                {
                  lightness: 29,
                },
                {
                  weight: 0.2,
                },
              ],
            },
            {
              featureType: 'road.arterial',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#ffffff',
                },
                {
                  lightness: 18,
                },
              ],
            },
            {
              featureType: 'road.local',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#ffffff',
                },
                {
                  lightness: 16,
                },
              ],
            },
            {
              featureType: 'poi',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#f5f5f5',
                },
                {
                  lightness: 21,
                },
              ],
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#dedede',
                },
                {
                  lightness: 21,
                },
              ],
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  visibility: 'on',
                },
                {
                  color: '#ffffff',
                },
                {
                  lightness: 16,
                },
              ],
            },
            {
              elementType: 'labels.text.fill',
              stylers: [
                {
                  saturation: 36,
                },
                {
                  color: '#333333',
                },
                {
                  lightness: 40,
                },
              ],
            },
            {
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'transit',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#f2f2f2',
                },
                {
                  lightness: 19,
                },
              ],
            },
            {
              featureType: 'administrative',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#fefefe',
                },
                {
                  lightness: 20,
                },
              ],
            },
            {
              featureType: 'administrative',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#fefefe',
                },
                {
                  lightness: 17,
                },
                {
                  weight: 1.2,
                },
              ],
            },
          ],
        }}
      >
        {props.data
          .filter(v => MARKER_COLORS[v.type])
          .map((v, i) => (
            <Marker
              key={i}
              position={{ lat: Number(v.lat), lng: Number(v.lng) }}
              icon={markerIcon({ type: v.type, size: 32 })}
            />
          ))}
      </GoogleMap>
      <div className={styles.controls}>
        <NavButton
          onClick={() => props.changeZoom('out')}
          type={'minus'}
          size={'small'}
          bgColor={'white'}
          marginRight
        />
        <NavButton
          onClick={() => props.changeZoom('in')}
          type={'plus'}
          size={'small'}
          bgColor={'white'}
        />
      </div>
    </>
  );
});

Billboardsmap.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      // lat: PropTypes.number,
      // lng: PropTypes.number,
    })
  ),
  zoom: PropTypes.number,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
};

Billboardsmap.defaultProps = {
  zoom: 10,
};

export default Billboardsmap;
