import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import BarBackground from './BarBackground';
import Bar from './Bar';

import styles from './styles.module.scss';

const ProgressBar = ({ color, label, value = 0, max }) => {
    return (
        <div className={cx(styles.progressBar)}>
            <div className={cx(styles.value)} style={{ color }}>
                {value !== null ? value.toFixed(2) : ''}
            </div>

            <div className={cx(styles.bar)}>
                <BarBackground {...{ color }} />
                <Bar {...{ color, max, value: value || 0 }} />
            </div>

            <div className={cx(styles.label)} style={{ color }}>
                {label}
            </div>
        </div>
    );
};

ProgressBar.propTypes = {
    // Bar and labels color
    color: PropTypes.string,
    // Label under the bar
    label: PropTypes.string,
    // Max risk value
    max: PropTypes.number,
    // Risk value
    value: PropTypes.number
};

export default ProgressBar;
