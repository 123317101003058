import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.module.scss';
import IconArrowDown from '@/assets/icons/arrow-down.svg';
import IconArrowUp from '@/assets/icons/arrow-up.svg';
import IconArrowLeft from '@/assets/icons/arrow-left.svg';
import IconMinus from '@/assets/icons/minus.svg';
import IconPlus from '@/assets/icons/plus.svg';

const NavButton = ({ type, size, marginRight, onClick, bgColor }) => {
  const buttonClassNames = cx(
    styles.root,
    styles[size],
    styles[bgColor],
    marginRight && styles[`marginRight${size.charAt(0).toUpperCase() + size.slice(1)}`]
  );
  const iconClassNames = cx(
    styles.icon,
    styles[`icon${size.charAt(0).toUpperCase() + size.slice(1)}`]
  );

  return (
    <button onClick={onClick} className={buttonClassNames}>
      {type === 'arrow-down' && <IconArrowDown className={iconClassNames} />}
      {type === 'arrow-up' && <IconArrowUp className={iconClassNames} />}
      {type === 'arrow-left' && <IconArrowLeft className={iconClassNames} />}
      {type === 'plus' && <IconPlus className={iconClassNames} />}
      {type === 'minus' && <IconMinus className={iconClassNames} />}
    </button>
  );
};

NavButton.propTypes = {
  onClick: PropTypes.func,
  marginRight: PropTypes.bool,
  type: PropTypes.oneOf(['arrow-down', 'arrow-up', 'arrow-left', 'plus', 'minus']).isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  bgColor: PropTypes.oneOf(['white']),
};

NavButton.defaultProps = {
  size: 'medium',
};

export default NavButton;
