import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.module.scss';

const IconPlus = ({ reverse, position }) => {
  return <div className={cx(styles.root, styles[position], reverse && styles.reverse)} />;
};

IconPlus.propTypes = {
  reverse: PropTypes.bool,
  position: PropTypes.oneOf(['left', 'right']),
};

export default IconPlus;
