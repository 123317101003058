import React, { Component, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import useOnClickOutside from 'use-onclickoutside';
import Close from '@/assets/icons/close.svg';

const Legend = ({ legend, description, close }) => {
    const ref = useRef(null);
    useOnClickOutside(ref, close);

    return (
        <div ref={ref} className={styles.legend}>
            <div className={styles.legendClose}>
                <Close />
            </div>
            <div className={styles.legendTitle}>Описание:</div>
            <div className={styles.legendDescription}>{description}</div>
            {Object.keys(legend).map((legendItemKey, legendItemIndex) => (
                <div key={legendItemIndex} className={styles.legendItem}>
                    <div
                        className={styles.legendItemIcon}
                        style={{ backgroundColor: legend[legendItemKey].backgroundColor }}
                    />
                    {legend[legendItemKey].altText || legend[legendItemIndex].text}
                </div>
            ))}
        </div>
    );
};

class ScoringResultDetailedLine extends Component {
    state = {
        isLegendOpen: false
    };

    toggleLegend = (e) => {
        e.stopPropagation();
        this.setState(({ isLegendOpen }) => ({ isLegendOpen: !isLegendOpen }));
    };

    render() {
        const { alias, value, legend, description, unit } = this.props;
        const { isLegendOpen } = this.state;

        const valueInfo = legend && legend[parseInt(value)];

        return (
            <div className={styles.detailedRow} onMouseDown={this.toggleLegend} style={{ order: legend ? 0 : 1 }}>
                <div className={styles.detailedTitle}>
                    {alias}&nbsp;&nbsp;&nbsp;{unit && <span>{unit}</span>}
                </div>
                <div className={styles.detailedValue}>
                    {valueInfo && (
                        <div
                            className={styles.detailedValueIndicator}
                            style={{ backgroundColor: valueInfo.backgroundColor }}>
                            {isLegendOpen && (
                                <Legend close={this.toggleLegend} legend={legend} description={description} />
                            )}
                        </div>
                    )}
                    {valueInfo ? valueInfo.text : Number.parseFloat(value).toFixed(2)}
                </div>
            </div>
        );
    }
}

export default ScoringResultDetailedLine;

ScoringResultDetailedLine.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
