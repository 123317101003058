import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import Chart from 'chart.js';

class IntroPieChart extends Component {
  componentDidMount() {
    let sm = this.props.value < 51;
    this.config = {
      type: 'pie',
      data: {
        datasets: [
          {
            data: [
              sm ? this.props.value : 100 - this.props.value,
              sm ? 100 - this.props.value : this.props.value,
            ],
            backgroundColor: [sm ? '#fff' : 'transparent', sm ? 'transparent' : '#fff'],
            borderWidth: [0, sm ? 1.5 : 0],
            hoverBackgroundColor: [sm ? '#fff' : 'transparent', sm ? 'transparent' : '#fff'],
          },
        ],
      },
      options: {
        responsive: true,
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
      },
    };

    this.pie = new Chart(this.$pie, this.config);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      let sm = nextProps.value < 51;
      this.config.data.datasets[0].data = [
        sm ? nextProps.value : 100 - nextProps.value,
        sm ? 100 - nextProps.value : nextProps.value,
      ];
      this.config.data.datasets[0].backgroundColor = [
        sm ? nextProps.value : 100 - nextProps.value,
        sm ? 100 - nextProps.value : nextProps.value,
      ];
      this.config.data.datasets[0].backgroundColor = [
        sm ? '#fff' : 'transparent',
        sm ? 'transparent' : '#fff',
      ];
      this.config.data.datasets[0].borderWidth = [0, sm ? 1.5 : 0];
      this.config.data.datasets[0].hoverBackgroundColor = [
        sm ? '#fff' : 'transparent',
        sm ? 'transparent' : '#fff',
      ];

      this.pie.update();
    }
  }

  render() {
    return (
      <div className={styles.root}>
        <canvas width="100%" height="100%" ref={ref => (this.$pie = ref)} />
      </div>
    );
  }
}

export default IntroPieChart;

IntroPieChart.propTypes = {
  value: PropTypes.oneOf(Array.from(Array(101).keys())).isRequired,
};
