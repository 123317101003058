import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';

import { Section } from '@/components/UI/Section';
import CollapseTransition from '@/components/UI/CollapseTransition';

import { CREDIT_SCORE_NAME } from '../../consts';
import ScoringButtonMore from './ScoringButtonMore';
import ScoringForm from './ScoringForm';
import ScoringResult from './ScoringResult';
import ScoringResultDetailed from './ScoringResultDetailed';
import styles from './styles.module.scss';

class Scoring extends Component {
    state = {
        addIsOpen: false
    };

    onChange = (e) => {
        this.setState({ value: e.target.value });
    };

    onSubmit = (value) => {
        this.props.onSubmit(value);
    };

    toggleIsOpen = () => {
        this.setState({ addIsOpen: !this.state.addIsOpen });
    };

    render() {
        const { data, isLoading, hasError } = this.props;
        return (
            <>
                <Section />
                <Section>
                    <div className={styles.title}>
                        <div className="H2">Рассчитать скоринговый балл</div>
                    </div>
                    <div className={styles.form}>
                        <ScoringForm onSubmit={this.onSubmit} onChange={this.onChange} />
                        <ScoringResult
                            isLoading={isLoading}
                            hasError={hasError}
                            result={data && !isLoading ? Number.parseFloat(data[CREDIT_SCORE_NAME].value) : null}
                        />
                    </div>
                    <div
                        className={cx(styles.more, {
                            [styles.disabled]: !data || isLoading
                        })}>
                        <div className={styles.moreTop} onClick={this.toggleIsOpen}>
                            <div className="H3">Дополнительные атрибуты верификации</div>
                            <ScoringButtonMore onClick={this.toggleIsOpen} isOpen={this.state.addIsOpen} />
                        </div>
                        <CollapseTransition in={this.state.addIsOpen}>
                            <div>
                                <div className={styles.addInfo}>
                                    <ScoringResultDetailed data={data} />
                                </div>
                            </div>
                        </CollapseTransition>
                    </div>
                </Section>
            </>
        );
    }
}

Scoring.propTypes = {
    data: PropTypes.object,
    hasError: PropTypes.bool,
    isLoading: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired
};

export default Scoring;
