import React from 'react';
import styles from './styles.module.scss';

const Industries = ({ industries }) => (
  <div>
    <h3>Для каких отраслей?</h3>
    <div className={styles.industries}>
      {industries.map((industry, industryIndex) => (
        <div key={industryIndex} className={styles.industriesItem}>
          {industry}
        </div>
      ))}
    </div>
  </div>
);

export default Industries;
