import React, { Component } from 'react';
import Layout from '@/components/layout';
import SEO from '@/components/seo';
import Scoring from '@/components/Scoring';
import NoSSR from '@/components/no-ssr';
import axios from 'axios';
import { SCORING_ATTRIBUTES, SCORING_VARIABLES, SCORING_VARIABLE_INFO } from '@/consts';

const PAGE = {
    pageTitle: 'Рисковый скоринг',
    sectionTitle: 'рисковый скоринг',
    pageDescription:
        'Сервис машинного обучения для управления кредитными и&nbsp;страховыми рисками. <br/><br/>С&nbsp;помощью сервиса прогнозируется вероятность наступления страхового случая или дефолта для физического и&nbsp;юридического лица. Получайте результат моментально с&nbsp;помощью интеграции по&nbsp;API.',
    introPieChart: {
        value: 75,
        description:
            'Всех решений о&nbsp;выдаче розничных кредитов в&nbsp;России в&nbsp;2017 году было принято с&nbsp;использованием сервиса МегаФона.'
    },
    industries: ['Банки', 'МФО', 'Страхование']
};

class ScoringPage extends Component {
    state = {
        isLoading: false,
        data: null
    };

    onSubmit = (value) => {
        this.setState({
            isLoading: true,
            hasError: false
        });
        const phoneNumber = value.replace(/\D/g, '');
        axios
            .post('/bigdata-back/api/risk', {
                // prod API endpoint
                // .post('https://bigdata.demo.megafon.ru/bigdata-back/api/risk', {
                // dev API endpoint
                data: {
                    msisdn: phoneNumber,
                    variables: SCORING_VARIABLES,
                    attributes: SCORING_ATTRIBUTES
                }
            })
            .then((result) => {
                this.setState({
                    isLoading: false
                });
                const hasVariables = result.data && result.data.data && result.data.data.variables;
                if (hasVariables) {
                    this.setState({
                        data: result.data.data.variables.reduce(
                            (agg, variable) => ({
                                ...agg,
                                [variable.name]: {
                                    ...variable,
                                    ...SCORING_VARIABLE_INFO[variable.name]
                                }
                            }),
                            {}
                        )
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    isLoading: false,
                    hasError: true
                });
            });
    };

    render() {
        const { data, isLoading, hasError } = this.state;
        return (
            <Layout page={PAGE}>
                <SEO title={PAGE.pageTitle} />
                <NoSSR>
                    <Scoring onSubmit={this.onSubmit} data={data} isLoading={isLoading} hasError={hasError} />
                </NoSSR>
            </Layout>
        );
    }
}

export default ScoringPage;
