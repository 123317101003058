import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.module.scss';

const ChartLabels = ({ labels }) => (
  <div className={styles.root}>
    {labels.map((v, i) => (
      <div className={styles.item} key={i}>
        <div className={styles.icon} style={{ backgroundColor: v.color }}></div>
        {v.text}
      </div>
    ))}
  </div>
);

ChartLabels.propTypes = {
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ChartLabels;
