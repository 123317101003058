import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chart from '../Chart.js';
import styles from './styles.module.scss';

class TwoSectionPieChart extends Component {
  constructor(props) {
    super(props);

    this.config = {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: this.props.data.map(e => e.value),
            backgroundColor: ['#00B956', '#10E272'],
            borderWidth: [0, 0],
            hoverBackgroundColor: ['#00B956', '#10E272'],
          },
        ],
        labels: this.props.data.map(e => e.title),
      },
      options: {
        cutoutPercentage: 20,
        onResize: this.handleResize,
      },
    };

    this.observer = new IntersectionObserver(this.observerCallback, {
      root: this.$root,
      threshold: 0.7,
    });
  }

  state = {
    graphIsMounted: false,
    sum: this.props.data.map(e => e.value).reduce((sum, cur) => (sum = sum + cur)),
    chartHeight: null,
  };

  handleResize = size => {
    if (size) {
      this.setState({
        chartHeight: size.height,
      });
    }
  };

  componentDidMount() {
    this.observer.observe(this.$root);
  }

  componentWillUnmount() {
    this.observer.unobserve(this.$root);
  }

  observerCallback = e => {
    if (e[0].isIntersecting && !this.state.graphIsMounted) {
      this.chart = new Chart(this.$chart, this.config);
      this.setState({ graphIsMounted: true });
      this.observer.unobserve(this.$root);
    }
  };

  getTooltipStyle = (value, index, invertYOffset) => {
    const chartHeight = this.chart.height;
    const [xOffset, yOffset] = [-15, 0, false];
    const isRight = index % 2 === 0;
    return {
      direction: isRight ? 'ltr' : 'rtl',
      transform: isRight
        ? `translate(calc(-${chartHeight / 2}px - ${100 + xOffset}%), ${
            invertYOffset ? yOffset : -yOffset
          }px)`
        : `translate(calc(${chartHeight / 2}px + ${xOffset}%), ${
            invertYOffset ? -yOffset : yOffset
          }px)`,
    };
  };

  render() {
    return (
      <div className={styles.root} ref={ref => (this.$root = ref)}>
        <canvas className={styles.canvas} ref={ref => (this.$chart = ref)} />
        {this.chart && this.chart.height && (
          <div
            className={styles.tooltips}
            style={{
              width: `${this.chart.height}px`,
              height: `${this.chart.height}px`,
            }}
          >
            {this.props.data.map((e, i) => (
              <div
                className={styles.tooltip}
                key={i}
                style={this.getTooltipStyle(e.value, i, this.props.invertTooltipsYOffset)}
              >
                {e.title}
                <span className={styles.tooltipPercent} key={i}>
                  {' '}
                  {Math.round(100- ((100 / this.state.sum) * e.value))}%{' '}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

TwoSectionPieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default TwoSectionPieChart;
