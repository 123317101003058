import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import roundInt from '@/components/utils/roundInt';

import styles from './styles.module.scss';

const calcFunctions = {
    linear: (x) => x,
    atan: (x, kx = 1, limY = 1) => {
        if (x <= 0) return 0;

        const ky = Math.PI / 2;
        return (limY * Math.atan(kx * x)) / ky;
    },
    linAtan: (x, kx = 1, limY = 1, threshold = 5 / 100) => {
        if (x < 0) return 0;
        // uses linear function with coefficient k
        if (x < threshold) {
            const k = calcFunctions.atan(threshold, kx, limY) / threshold;
            return k * x;
        }
        // uses atan function
        if (x >= threshold) return calcFunctions.atan(x, kx, limY);
    }
};

/**
 * Returns bar width in percents
 * @param max maximum available value
 * @param value current value
 * @returns {number} percents
 */
const getBarWidth = ({ max, value }) => {
    if (!value) return 0;

    // prevents divide by 0
    const limY = max || 1;

    // fill bar on very small values
    if (limY * value < 0.005) return 100;

    // normalizes value
    const _value = calcFunctions.linAtan(value, 75, limY) / limY;

    return roundInt(1 - _value, 2) * 100;
};

const Bar = ({ color, max, opacity = 1, value = 0 }) => {
    const barWidth = useMemo(() => getBarWidth({ max, value }), [max, value]);

    return <div className={cx(styles.bar)} style={{ background: color, opacity, width: `${barWidth}%` }} />;
};

Bar.propTypes = {
    // Bar color
    color: PropTypes.string,
    // Max bar value
    max: PropTypes.number,
    // Bar value
    value: PropTypes.number
};

export default Bar;
