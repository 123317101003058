import React from 'react';
import PropTypes from 'prop-types';
import IntroPieChart from '../IntroPieChart/';
import styles from './styles.module.scss';

const IntroPieChartBlock = ({ value, description, prefix }) => {
  return (
    <div className={styles.root}>
      <div className={styles.text}>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      <div className={styles.bigText}>
        <div className={styles.bigTextInner}>
          {prefix && <div className={styles.prefix}>{prefix}</div>}%{value}
        </div>
      </div>
      <div className={styles.pie}>
        <div className={styles.pieInner}>
          <IntroPieChart value={value} />
        </div>
      </div>
    </div>
  );
};

export default IntroPieChartBlock;

IntroPieChartBlock.propTypes = {
  value: PropTypes.number.isRequired,
  description: PropTypes.string,
};
