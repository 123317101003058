import React from 'react';
import PropTypes from 'prop-types';
import { StyledButtonSmall } from '@/components/UI/Button';
import cx from 'classnames';
import styles from './styles.module.scss';

const IntroBranches = ({ title, list }) => {
  return (
    <div className={styles.root}>
      <div className={cx(styles.title, 'H3')}>{title}</div>
      <div className={styles.buttons}>
        {list.map((v, i) => (
          <StyledButtonSmall key={i} className={styles.button}>
            {v}
          </StyledButtonSmall>
        ))}
      </div>
    </div>
  );
};

export default IntroBranches;

IntroBranches.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
};
