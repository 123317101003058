import React, { useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import { INDEX_ROUTE } from '../consts';
import { AuthContext } from '../components/AuthContext';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
    const [isLoggedIn] = useContext(AuthContext);
    useEffect(() => {
        if (!isLoggedIn) {
            navigate(INDEX_ROUTE);
        }
    }, [isLoggedIn]);

    return isLoggedIn ? <Component {...rest} /> : null;
};

export default PrivateRoute;
